const getInputClasses = ({ errors, touched }, field) =>
  `input ${errors[field] && touched[field] && 'is-danger'}`;

const helpText = (formik, field, defaultText = '\u00A0') => {
  if (!formik.touched[field]) return <p className="help">{defaultText}</p>;

  if (formik.errors[field]) return <p className="help is-danger">{formik.errors[field]}</p>

  return <p className="help">&nbsp;</p>;
}

// Interests are things that Users have
const validInterestOptions = [
  { value: 'community_resilience', label: 'Community Resilience' },
  { value: 'emergency_preparedness', label: 'Emergency Preparedness' },
  { value: 'emergency_response', label: 'Emergency Response' },
  { value: 'self_sustainability', label: 'Self-Sustainability' },
];

const validInterestValues = validInterestOptions.map(v => v.value);

const optionsListToMap = options =>
  options.reduce((acc, { value, label }) => ({
    ...acc,
    [value]: label
  }), {});

const isRequired = (schema, field) => !!schema.fields[field]?.exclusiveTests.required;

const labelClasses = (schema, field) => 'label' + (isRequired(schema, field) ? ' required' : '');

const validInterestOptionMap = optionsListToMap(validInterestOptions);

export {
  getInputClasses,
  helpText,
  isRequired,
  labelClasses,
  validInterestValues,
  validInterestOptions,
  validInterestOptionMap,
};
