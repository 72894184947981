import Link from 'next/link';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import InputField from '../components/form-fields/InputField';
import useUser from '@/components/useUser';

const formSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required()
});

const Login = () => {
  const router = useRouter();
  const { user, refreshUser } = useUser();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: formSchema,
    onSubmit: async (params, { setSubmitting, setErrors }) => {
      const response = await fetch('/api-auth/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      });

      const body = await response.json();

      if (response.status === 200) {
        toast.success(`welcome back ${params.username}!`);
        refreshUser();

        const path = new URLSearchParams(window.location.search).get('next') || '/';
        return router.push(path);
      }

      setErrors({ login: body.non_field_errors.join(" ") });
    }
  });

  if (user) {
    const path = new URLSearchParams(window.location.search).get('next') || '/';
    router.push(path);
  }

  return (
    <section className="section">
      <div className="container" style={{maxWidth: 480}}>
        <div className="px-6">
          {formik.errors.login && (
            <div className="notification">
              <p>Unable to log in with provided credentials.</p>
            </div>
          )}

          {user && (
            <div className="notification">
              <p>You are already logged in! Redirecting you to the home page...</p>
            </div>
          )}
        </div>

        <div className="_box p-6">
          <form onSubmit={formik.handleSubmit}>
            <InputField fieldName="username" label="Username" formik={formik} />
            <InputField fieldName="password" label="Password" formik={formik} type="password" />

            <button
              type="submit"
              className="button is-success is-fullwidth mt-5"
              disabled={formik.isSubmitting}
            >
              Login
            </button>

            <div className="has-text-centered mb-5">
              <div className="py-3">or</div>
              <Link href={`/login/magic-link`} className="button is-warning is-fullwidth">
                Login via Link
              </Link>
            </div>

          </form>

          <div className="has-text-centered">
            <small><Link href={`/profile/password-reset`}>Forgot your password?</Link></small>
          </div>

          <div className="has-text-centered mt-3">
            <small>Don't have an account? <Link href={`/signup`}>Sign Up</Link></small>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
