import { getInputClasses, helpText } from './common';

const InputField = ({ className = '', formik, fieldName, label, type = 'text', placeholder = '', isHidden }) => (
  <div className={`field ${isHidden ? 'is-hidden' : ''}`}>
    <label className="label" htmlFor={fieldName}>{label}</label>
    <div className="control">
      <input
        className={`${getInputClasses(formik, fieldName)} ${className}`}
        id={fieldName}
        type={type}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={placeholder}
        value={formik.values[fieldName]} />
    </div>
    {helpText(formik, fieldName)}
  </div>
);

export default InputField;
